import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  TextInput,
  Button,
  Modal,
  Flex,
  Title,
  Textarea,
  Text,
  Select,
  Grid,
  useMantineTheme,
  Stack,
  Group,
  Popover,
  Switch,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import CommonService from 'Api/commonService';
// import PolicyService from 'Api/policyService';
import RuleService from 'Api/ruleService';
import TagService from 'Api/tagService';
import ExpressionInput from 'Components/expression-input/ExpressionInput';
import { RuleEvalStatus } from 'Constants/index';
import { RuleType } from 'Types/ruleTypes';
import { TagSpecType, TransformedTagSpecType } from 'Types/tagTypes';
import {
  showErrorNotification,
  showInfoNotification,
  showSuccessNotification,
} from 'Utils/notifications';
import { transformDateString, transformTagSpecData } from 'Utils/transform';

import NestedDropdowns from './NestedDropdown';

type AddRuleProps = {
  openAddRuleDrawer: boolean;
  setOpenAddRuleDrawer: (open: boolean) => void;
  setRuleToView: (rule: any) => void;
  ruleToView: RuleType | null;
  refreshRules: () => void;
};

const initialFormValues: Partial<RuleType> = {
  title: '',
  expression: { script: '' },
  is_active: false,
};

const AddRuleModal: React.FC<AddRuleProps> = ({
  openAddRuleDrawer,
  setOpenAddRuleDrawer,
  ruleToView,
  refreshRules,
}) => {
  const [addFormValues, setAddFormValues] = useState<Partial<RuleType>>({
    ...initialFormValues,
  });
  const [tagLabel, setTagLabel] = useState('');
  const [subTagValue, setSubTagValue] = useState<string>('');
  const [newTagPopoverOpened, handleNewTagPopover] = useDisclosure(false);
  const [tagSpecs, setTagSpecs] = useState<TransformedTagSpecType[] | []>([]);

  const theme = useMantineTheme();
  const navigate = useNavigate();

  useEffect(() => {
    fetchMetaData();
  }, []);

  useEffect(() => {
    if (ruleToView) {
      setAddFormValues(ruleToView);
    } else {
      setAddFormValues(initialFormValues);
    }
  }, [ruleToView]);

  useEffect(() => {
    fetchTagSpecs();
  }, []);

  // @todo : move this to store so that it can be used in other components
  const fetchMetaData = async () => {
    const result = await CommonService.getMetaContentTypes();
    console.log(result, 'meta');
  };

  const fetchTagSpecs = async () => {
    try {
      const { data } = await TagService.getTagSpecs();
      if (data && data.results) {
        const transformedData = transformTagSpecData(data.results);
        setTagSpecs(transformedData);
      } else {
        throw new Error('Error while fetching tag specs');
      }
    } catch (_) {
      showErrorNotification('Error while fetching rule tags');
    }
  };

  const updateTagChanges = async () => {
    if (!ruleToView?.id) return; // since BE handle tags on creation
    if (!isEqual(addFormValues.tags, ruleToView.tags)) {
      // find out which tags are newly added and which are removed
      const newTags = addFormValues.tags?.filter((tag) => !tag.id);
      const removedTags = ruleToView.tags?.filter(
        (tag) => !addFormValues.tags?.some((t) => t.id === tag.id)
      );
      if (newTags && newTags.length > 0) {
        await Promise.all(
          newTags.map((newTag) =>
            TagService.createTags({
              ...newTag,
              entity_id: ruleToView.id,
              entity_type: 'rules.rule',
            })
          )
        );
      }
      if (removedTags && removedTags.length > 0) {
        await Promise.all(
          removedTags.map((removedTag) => TagService.deleteTags(removedTag.id))
        );
      }
      return;
    }
  };

  const handleSubmit = async () => {
    try {
      if (ruleToView?.id) {
        const response = await RuleService.updateRules(
          ruleToView.id,
          addFormValues
        );
        await updateTagChanges();
        if (response && response.data) {
          showInfoNotification(
            'Rule Updated',
            'Rule has been updated successfully'
          );
          setOpenAddRuleDrawer(false);
          refreshRules();
        }
      } else {
        const response = await RuleService.createRules({
          ...addFormValues,
        });
        if (response && response.data) {
          showSuccessNotification(
            'Rule Added',
            'Rule has been added successfully'
          );

          setOpenAddRuleDrawer(false);
          setAddFormValues(initialFormValues);
          refreshRules();
        }
      }
    } catch (error: any) {
      showErrorNotification(error.message || 'Something went wrong');
    }
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setAddFormValues({
      ...addFormValues,
      [event.target.name]: event.target.value,
    });
  };

  const createNewTag = async () => {
    try {
      const tagSpec: Partial<TagSpecType> = {
        label: tagLabel,
        data_type: 'string',
        constraints: {
          allowed_values: [{ value: subTagValue, is_active: true }],
        },
      };
      await TagService.createTagSpecs(tagSpec);
      handleNewTagPopover.close();
      showSuccessNotification('Tag created successfully');
      fetchTagSpecs();
    } catch (e: any) {
      showErrorNotification(e?.message || 'Error while saving tag');
    }
  };

  return (
    <Modal
      size="xl"
      p="md"
      opened={openAddRuleDrawer}
      onClose={() => setOpenAddRuleDrawer(false)}
      title={
        <Flex justify="space-between">
          <Title order={4}>
            {ruleToView?.id ? `Update` : `Add New`} <strong>Rule</strong>
          </Title>
        </Flex>
      }
      styles={{
        content: {
          borderLeft: `9px solid ${theme.colors.primary[6]}`,
          padding: 20,
        },
      }}
    >
      <Grid gutter={40}>
        <Grid.Col span={5}>
          <TextInput
            value={addFormValues.title}
            name="title"
            label="Title"
            placeholder="Title"
            required
            onChange={handleChange}
            size="sm"
          />
          <Textarea
            value={addFormValues.description || ''}
            name="description"
            label="Description"
            placeholder="This rules look validates...."
            required
            onChange={handleChange}
            minRows={3}
            autosize
            maxRows={10}
            mt="sm"
          />
          <Select
            mt="sm"
            size="sm"
            name="on_true_eval_status"
            label="On True Eval Status"
            placeholder="Eval status on True"
            onChange={(value) => {
              if (!value) return;
              setAddFormValues({
                ...addFormValues,
                on_true_eval_status: parseInt(value),
              });
            }}
            data={Object.keys(RuleEvalStatus).map((status) => {
              const key = status as keyof typeof RuleEvalStatus;
              return {
                value: key,
                label: RuleEvalStatus[key],
              };
            })}
            value={addFormValues.on_true_eval_status?.toString()}
          />
          <Select
            mt="sm"
            size="sm"
            name="on_false_eval_status"
            label="On False Eval Status"
            placeholder="Eval status on false"
            onChange={(value) => {
              if (!value) return;
              setAddFormValues({
                ...addFormValues,
                on_false_eval_status: parseInt(value),
              });
            }}
            data={Object.keys(RuleEvalStatus).map((status) => {
              const key = status as keyof typeof RuleEvalStatus;
              return {
                value: key,
                label: RuleEvalStatus[key],
              };
            })}
            value={addFormValues.on_false_eval_status?.toString()}
          />
          <Select
            mt="sm"
            size="sm"
            name="on_error_eval_status"
            label="On Error Eval Status"
            placeholder="Eval status on error"
            onChange={(value) => {
              if (!value) return;
              setAddFormValues({
                ...addFormValues,
                on_error_eval_status: parseInt(value),
              });
            }}
            data={Object.keys(RuleEvalStatus).map((status) => {
              const key = status as keyof typeof RuleEvalStatus;
              return {
                value: key,
                label: RuleEvalStatus[key],
              };
            })}
            value={addFormValues.on_error_eval_status?.toString()}
          />
        </Grid.Col>
        <Grid.Col span={7}>
          <Stack gap="sm">
            <ExpressionInput
              minRows={6}
              maxRows={12}
              autosize
              value={addFormValues.expression?.script}
              name="expression.script"
              label="Create Automation"
              placeholder="Write your prompt expression here"
              required
              onChange={(event) => {
                setAddFormValues({
                  ...addFormValues,
                  expression: { script: event.target.value, is_valid: true },
                });
              }}
            />
            <Select label="Permissions" value="Everyone" data={['Everyone']} />

            <Group mt="sm" justify="space-between" align="center">
              <Text fw={500} size="sm">
                Associate Tags
              </Text>
              <Popover
                width={200}
                trapFocus
                position="bottom"
                withArrow
                shadow="md"
                opened={newTagPopoverOpened}
                onClose={() => handleNewTagPopover.close()}
              >
                <Popover.Target>
                  <Button
                    onClick={() => handleNewTagPopover.open()}
                    variant="link"
                  >
                    + Create New
                  </Button>
                </Popover.Target>
                <Popover.Dropdown>
                  <TextInput
                    label="Tag Name"
                    size="xs"
                    value={tagLabel}
                    onChange={({ target }) => setTagLabel(target.value)}
                  />
                  <TextInput
                    label="Add Sub Tag (Optional)"
                    size="xs"
                    value={subTagValue}
                    onChange={({ target }) => setSubTagValue(target.value)}
                  />
                  <Button
                    onClick={() => {
                      navigate('/tag-management');
                    }}
                    size="xs"
                    mt="lg"
                    variant="link"
                  >
                    Visit Tag Management
                  </Button>
                  <Flex mt="md" mb="md">
                    <Button
                      onClick={createNewTag}
                      size="xs"
                      variant="filled-shadow"
                      disabled={!tagLabel}
                    >
                      Create
                    </Button>
                    <Button
                      onClick={() => handleNewTagPopover.close()}
                      size="xs"
                      variant="subtle"
                    >
                      Cancel
                    </Button>
                  </Flex>
                </Popover.Dropdown>
              </Popover>
            </Group>
            <NestedDropdowns
              associatedTags={addFormValues.tags || []}
              setAddFormValues={setAddFormValues}
              tagSpecs={tagSpecs}
            />
            <Group mt="md">
              <Switch
                size="md"
                label="Status"
                offLabel="InActive"
                onLabel="Active"
                checked={addFormValues.is_active}
                onChange={(event) => {
                  setAddFormValues({
                    ...addFormValues,
                    is_active: event.currentTarget.checked,
                  });
                }}
              />
            </Group>
          </Stack>
        </Grid.Col>
      </Grid>

      <Grid mt="lg">
        <Grid.Col span={8}>
          {ruleToView?.id && (
            <>
              <Text c="dimmed" size="sm">
                Last Updated By : {ruleToView.updated_by?.username} at{' '}
                {transformDateString(ruleToView.updated_at)}
              </Text>
              <Text c="dimmed" size="sm">
                Created By : {ruleToView.created_by?.username} at{' '}
                {transformDateString(ruleToView.created_at)}
              </Text>
            </>
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Flex justify="flex-end" style={{ width: '100%' }}>
            <Button
              size="sm"
              variant="filled-shadow"
              onClick={handleSubmit}
              mt="lg"
            >
              {ruleToView?.id ? 'Update' : 'Save'}
            </Button>
          </Flex>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default AddRuleModal;
