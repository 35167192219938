import { useEffect, useRef, useState } from 'react';

import { Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import CopilotService from 'Api/copilotService';
import ChatInput from 'Components/chat-input/ChatInput';
import ChatMessage from 'Components/chat-message/ChatMessage';
import { MessageTypes } from 'Types/chatTypes';
import { DocumentDataType } from 'Types/docTypes';

import classes from './ChatWindow.module.scss';

type ChatWindowProps = {
  documentData: DocumentDataType | null;
  copilotMessages: MessageTypes[];
  setCopilotMessages: React.Dispatch<React.SetStateAction<MessageTypes[]>>;
};

const ChatWindow: React.FC<ChatWindowProps> = ({
  documentData,
  copilotMessages,
  setCopilotMessages,
}) => {
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  // State to hold the current input value
  const [inputValue, setInputValue] = useState('');

  // useEffect(() => {
  //   const savedMessages = localStorage.getItem('messages');
  //   if (savedMessages) {
  //     setMessages(JSON.parse(savedMessages));
  //   }
  // }, []);

  // To automatically scroll chat box to the latest message
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [copilotMessages]);

  const handleSendMessage = async () => {
    console.log(documentData, 'Document Data in chat');
    try {
      const promptValue = inputValue;
      setInputValue('');
      // OPTIMISTIC UPDATE
      setCopilotMessages((prevMessages) => {
        const newMessages = [
          ...prevMessages,
          { sender: 'user', data: promptValue },
        ];
        return newMessages;
      });
      setLoading(true);
      // Save the input first
      if (!documentData?.latest_snapshot?.id)
        return notifications.show({
          title: 'Error',
          message: 'Document snapshot not found',
          color: 'red',
        });
      const {
        data: { response_message, result_type },
      } = await CopilotService.sendMessage(
        promptValue,
        documentData.latest_snapshot.id
      );
      const messagesToAppend = [
        { sender: 'copilot', data: response_message, resultType: result_type },
      ];
      setCopilotMessages((prevMessages) => {
        const newMessages = [...prevMessages, ...messagesToAppend];
        return newMessages;
      });
      setLoading(false);
    } catch (err) {
      notifications.show({
        title: 'Error',
        message: 'Something went wrong',
        color: 'red',
      });
      setLoading(false);
    }
  };

  return (
    <div className={classes.chatWindow}>
      <div className={classes.messages}>
        {copilotMessages.map((message, idx) => (
          <ChatMessage key={idx} {...message} />
        ))}
        {loading ? (
          <div style={{ margin: '40px auto' }}>
            <Loader size={30} />
          </div>
        ) : null}
        <div style={{ marginBottom: 100 }} ref={chatEndRef}></div>
      </div>
      <ChatInput
        float
        inputValue={inputValue}
        setInputValue={setInputValue}
        onKeyPress={handleSendMessage}
        handleSendMessage={handleSendMessage}
      />
    </div>
  );
};

export default ChatWindow;
