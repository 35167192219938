import Messages from 'Messages';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';

import { notifications } from '@mantine/notifications';
import { getUserInfo } from 'Src/utils/transform';

const {
  auth: { INVALID_CREDENTIALS, UNAUTHORIZED },
} = Messages;

const API_URL = import.meta.env.VITE_API_URL;
// const API_VERSION = import.meta.env.VITE_API_VERSION;
const API_VERSION = 'v1';

type ApiPathType = {
  REVIEWS: string;
};

type QueryParamsType = Record<string, Record<string, any>> | undefined;

const API_PATH: ApiPathType = {
  REVIEWS: `/api/${API_VERSION}/reviews/`,
};

const request = async (options: AxiosRequestConfig): Promise<any> => {
  const { token: TOKEN, currentTenant: TENANT_ID } = getUserInfo();
  try {
    const headers = {
      ...(TOKEN ? { Authorization: `Token ${TOKEN} ${TENANT_ID}` } : {}),
      ...options.headers,
    };

    return await axios.request({
      baseURL: API_URL,
      withCredentials: true,
      ...options,
      headers,
    });
  } catch (error: any) {
    if (error?.response?.status === 401) {
      notifications.show({
        color: 'red',
        title: INVALID_CREDENTIALS.title,
        message: INVALID_CREDENTIALS.message,
        style: { width: 400, margin: 'auto' },
      });
      if (window.location.pathname !== '/login') {
        return window.location.replace('/login');
      }
      return;
    }
    if (error?.response?.status === 403) {
      return notifications.show({
        color: 'red',
        title: UNAUTHORIZED.title,
        message: UNAUTHORIZED.message,
      });
    }
    if (!error.response) console.error(error.toJSON());
    const throwablesPaths = [
      'response.data.error',
      'response.data.message',
      'message',
    ];
    const toThrow = throwablesPaths
      .map((path) => _.get(error, path))
      .find((x) => x);
    throw new Error(toThrow);
  }
};

type CapitalizeWords<S extends string> = S extends `${infer T}-${infer U}`
  ? `${Capitalize<T>}${CapitalizeWords<U>}`
  : Capitalize<S>;
type CRUDOps<T, Entity extends string> = {
  [K in
    | `get${CapitalizeWords<Entity>}`
    | `get${CapitalizeWords<Entity>}ById`
    | `create${CapitalizeWords<Entity>}`
    | `update${CapitalizeWords<Entity>}`
    | `patch${CapitalizeWords<Entity>}`
    | `delete${CapitalizeWords<Entity>}`]: K extends `update${CapitalizeWords<Entity>}`
    ? (id: number, data: T) => Promise<AxiosResponse>
    : K extends `patch${CapitalizeWords<Entity>}`
      ? (id: number, data: Partial<T>) => Promise<AxiosResponse>
      : K extends `get${CapitalizeWords<Entity>}`
        ? (params?: Record<string, any>) => Promise<AxiosResponse>
        : K extends
              | `get${CapitalizeWords<Entity>}ById`
              | `delete${CapitalizeWords<Entity>}`
          ? (id: number) => Promise<AxiosResponse>
          : K extends `create${CapitalizeWords<Entity>}`
            ? (data: T) => Promise<AxiosResponse>
            : () => Promise<AxiosResponse>;
};

const getRequestWithFilters = (queryParams: QueryParamsType, url: string) => {
  const { filters } = queryParams || {};
  let newUrl: string = url;

  if (filters && Object.keys(filters).length > 0) {
    const queryParams = Object.entries(filters)
      .map(([key, value]) => {
        const paramValue = Array.isArray(value) ? value.join(',') : value;
        return `${encodeURIComponent(key)}=${encodeURIComponent(paramValue)}`;
      })
      .join('&');
    newUrl = `${newUrl}?${queryParams}`;
  }
  /* We can do rest of stuff like overriding/adding pagination, etc*/
  return request({ method: 'get', url: newUrl });
};

const generateCRUDOps = <T, Entity extends string>(
  basePath: string,
  entity: Entity
): CRUDOps<T, Entity> => {
  const url = `${basePath}${entity}/`;
  const words = entity.split('-');
  const funcName = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');

  return {
    [`get${funcName}`]: (queryParams?: QueryParamsType) =>
      getRequestWithFilters(queryParams, url),
    [`get${funcName}ById`]: (id: number) =>
      request({ method: 'get', url: `${url}${id}/` }),
    [`create${funcName}`]: (data: T) => request({ method: 'post', url, data }),
    [`update${funcName}`]: (id: number, data: T) =>
      request({ method: 'put', url: `${url}${id}/`, data }),
    [`patch${funcName}`]: (id: number, data: Partial<T>) =>
      request({ method: 'patch', url: `${url}${id}/`, data }),
    [`delete${funcName}`]: (id: number) =>
      request({ method: 'delete', url: `${url}${id}/` }),
  } as CRUDOps<T, Entity>;
};

export { request, API_PATH, generateCRUDOps };
