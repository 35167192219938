import { kebabCase } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import {
  Group,
  Text,
  Accordion,
  Grid,
  Checkbox,
  Switch,
  UnstyledButton,
  Box,
  Button,
  Menu,
  useMantineTheme,
  Input,
  Flex,
  Stack,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import {
  IconArrowRight,
  IconChevronDown,
  IconDotsVertical,
  IconEdit,
  IconTrash,
} from '@tabler/icons-react';
import {
  SubTagType,
  TagSpecType,
  TransformedTagSpecType,
} from 'Types/tagTypes';
import { showErrorNotification } from 'Utils/notifications';
import { transformDateString } from 'Utils/transform';

import AddSubTagModal from './AddSubTagModal';

interface ListTagSpecsProps {
  tagSpecs: TransformedTagSpecType[];
  setIsEditMode: (isEditMode: boolean) => void;
  setTagDataForModal: (tagData: any) => void;
  handleAddEditTagModal: { open: () => void; close: () => void };
  deleteTagSpec: (id: number, subTag?: string) => void;
  updateTagSpec: (tagId: number, tagData: Partial<TagSpecType>) => void;
  refreshList: () => void;
  selectedTags: Set<string>;
  setSelectedTags: (selectedTags: Set<string>) => void;
}

interface AccordionLabelProps {
  tagData: TransformedTagSpecType;
}

interface SubTagPanelProps {
  tagId: number;
  subTag: SubTagType;
  updateTagSpec: (tagId: number, tagData: Partial<TagSpecType>) => void;
}

const ListTagSpecs: React.FC<ListTagSpecsProps> = ({
  tagSpecs,
  setIsEditMode,
  setTagDataForModal,
  handleAddEditTagModal,
  deleteTagSpec,
  updateTagSpec,
  refreshList,
  selectedTags,
  setSelectedTags,
}) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [openAddSubTagModal, handleAddSubTagModal] = useDisclosure(false);
  const theme = useMantineTheme();

  const handleTagSelection = (tagKey: string) => {
    const prevSelectedTags = new Set<string>(selectedTags);
    if (prevSelectedTags.has(tagKey)) {
      prevSelectedTags.delete(tagKey);
    } else {
      prevSelectedTags.add(tagKey);
    }
    setSelectedTags(prevSelectedTags);
  };

  // const handleTagSelection = (tagKey: string) => {
  //   setSelectedTags((prevSelectedTags) => {
  //     const newSelectedTags = new Set(prevSelectedTags);
  //     if (newSelectedTags.has(tagKey)) {
  //       newSelectedTags.delete(tagKey);
  //     } else {
  //       newSelectedTags.add(tagKey);
  //     }
  //     return newSelectedTags;
  //   });
  // };

  const handleChevronClick = (tagId: string) => {
    setExpandedItems((prevExpandedItems) => {
      if (prevExpandedItems.includes(tagId)) {
        return prevExpandedItems.filter((id) => id !== tagId);
      } else {
        return [...prevExpandedItems, tagId];
      }
    });
  };

  const showTagDetailModal = (tagData: TransformedTagSpecType) => {
    if (!tagData)
      return showErrorNotification(
        'Something went wrong while opening details page'
      );
    return modals.open({
      title: (
        <Flex justify="space-between">
          <Title order={3}>
            Details | <strong>{tagData.label}</strong>
          </Title>
        </Flex>
      ),
      size: 'lg',
      styles: {
        content: {
          borderLeft: `9px solid ${theme.colors.primary[6]}`,
          padding: 20,
        },
      },
      children: (
        <Box>
          <Grid mt="lg">
            <Grid.Col span={3}>
              <Stack gap="xs">
                <Text size="sm">Created by</Text>
                <Text size="sm">{tagData.created_by?.first_name || ''}</Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={3}>
              <Stack gap="xs">
                <Text size="sm">Date Created</Text>
                <Text size="sm">{transformDateString(tagData.created_at)}</Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={3}>
              <Stack gap="xs">
                <Text size="sm">Last Modified</Text>
                <Text size="sm">{transformDateString(tagData.updated_at)}</Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={3}>
              <Stack gap="xs">
                <Text size="sm">Updated by</Text>
                <Text size="sm">{tagData.updated_by?.first_name || ''}</Text>
              </Stack>
            </Grid.Col>
          </Grid>
          <Flex gap="sm" mt="xl">
            <Stack gap="xs">
              <Text size="sm" mb="sm">
                Subtags
              </Text>
              <Group>
                {tagData.subTags.map(({ value }, index) => (
                  <Box
                    style={{
                      background: 'white',
                      border: '1px solid black',
                      padding: '3px 10px',
                    }}
                    key={index}
                  >
                    <Group gap="xs" align="center">
                      <Text size="sm">{value}</Text>
                    </Group>
                  </Box>
                ))}
              </Group>
            </Stack>
          </Flex>
          <Flex justify="flex-end">
            <Button
              size="sm"
              variant="filled-shadow"
              onClick={() => modals.closeAll()}
              mt="lg"
            >
              Close
            </Button>
          </Flex>
        </Box>
      ),
    });
  };

  const AccordionLabel: React.FC<AccordionLabelProps> = ({ tagData }) => (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Box style={{ flex: 1, alignItems: 'center' }}>
        <Grid align="center">
          <Grid.Col span={4}>
            <Group align="center" gap="sm">
              <Checkbox
                onChange={() => handleTagSelection(tagData.id.toString())}
                label={tagData.label}
                checked={selectedTags.has(tagData.id.toString())}
              />
              <div
                onClick={() => {
                  handleChevronClick(tagData.id.toString());
                }}
                style={{ marginLeft: 'auto', cursor: 'pointer' }}
              >
                <IconChevronDown
                  size={20}
                  style={{
                    transform: expandedItems.includes(tagData.id.toString())
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)',
                    transition: 'transform 0.2s',
                  }}
                />
              </div>
            </Group>
          </Grid.Col>
          <Grid.Col span={6}>
            <Grid align="center">
              <Grid.Col span={4} style={{ textAlign: 'center' }}>
                <Text size="sm">{tagData.subTags?.length ?? 0}</Text>
              </Grid.Col>
              <Grid.Col span={4} style={{ textAlign: 'center' }}>
                <Text size="sm">27</Text>
              </Grid.Col>
              <Grid.Col
                span={4}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Switch
                  color="teal"
                  checked={!!tagData.isActive}
                  onChange={(event) => {
                    console.log(event, updateTagSpec);
                    updateTagSpec(tagData.id, {
                      id: tagData.id,
                      is_active: event.currentTarget.checked,
                    });
                  }}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Box>
      <Menu>
        <Menu.Target>
          <IconDotsVertical size={20} style={{ cursor: 'pointer' }} />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            onClick={() => {
              setIsEditMode(true);
              handleAddEditTagModal.open();
              setTagDataForModal(tagData);
            }}
          >
            Edit Tag
          </Menu.Item>
          <Menu.Item onClick={handleAddEditTagModal.open}>
            Add a sub-tag
          </Menu.Item>
          <Menu.Item onClick={() => deleteTagSpec(tagData.id)}>
            <Text size="sm" c={theme.colors.primary[6]}>
              Delete Tag
            </Text>
          </Menu.Item>
          <Menu.Item onClick={() => showTagDetailModal(tagData)}>
            Details
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Box>
  );

  const SubTagPanel: React.FC<SubTagPanelProps> = ({
    tagId,
    subTag,
    updateTagSpec,
  }) => {
    const [editTag, setEditTag] = useState(false);
    const [subTagValue, setSubTagValue] = useState(subTag.value);
    const subTagEditInput = useRef<HTMLInputElement>(null);
    const subTagKey = `${tagId}-${kebabCase(subTag.value)}`;
    const tagSpec = tagSpecs.find(({ id }) => id === tagId);

    const updateSubTag = () => {
      if (!tagSpec) return showErrorNotification('Tag not found');
      const updatedSubTags = tagSpec.subTags.map((subTagItem) =>
        subTagItem.value === subTag.value
          ? { value: subTagValue, is_active: subTagItem.is_active }
          : subTagItem
      );
      updateTagSpec(tagId, {
        id: tagId,
        constraints: { allowed_values: updatedSubTags },
      });
    };

    const handleStatusChange = (isActive: boolean) => {
      const tagToBeUpdated = tagSpecs.find(({ id }) => id === tagId);
      if (!tagToBeUpdated) showErrorNotification('Tag not found');
      const subTagToBeUpdated = tagToBeUpdated?.subTags.find(
        ({ value }) => value === subTag.value
      );
      if (!subTagToBeUpdated) showErrorNotification('SubTag not found');
      const restOfSubTags =
        tagToBeUpdated?.subTags.filter(({ value }) => value !== subTag.value) ||
        [];
      updateTagSpec(tagId, {
        id: tagId,
        constraints: {
          allowed_values: [
            ...restOfSubTags,
            { value: subTag.value, is_active: isActive },
          ],
        },
      });
    };

    useEffect(() => {
      if (editTag && subTagEditInput.current) {
        subTagEditInput.current.focus();
      }
    }, [editTag]);

    return (
      <Box style={{ display: 'flex', alignItems: 'center' }} key={subTagKey}>
        <Box style={{ flex: 1, alignItems: 'center' }}>
          <Grid align="center">
            <Grid.Col span={4} pl={40}>
              <Group align="center" gap="sm">
                {editTag ? (
                  <Group gap={0}>
                    <Input
                      ref={subTagEditInput}
                      styles={{
                        wrapper: { backgroundColor: '#f1f3f5' },
                        input: { fontSize: 18 },
                      }}
                      bg="#f1f3f5"
                      value={subTagValue}
                      onChange={(event) => setSubTagValue(event.target.value)}
                      size="xs"
                    ></Input>
                    <Button
                      onClick={updateSubTag}
                      size="compact-md"
                      variant="outline"
                    >
                      <IconArrowRight />
                    </Button>
                  </Group>
                ) : (
                  <Group>
                    <Text size="sm">{subTag.value}</Text>
                    <UnstyledButton
                      onClick={() => {
                        setEditTag(true);
                      }}
                      h={20}
                      mb={5}
                    >
                      <IconEdit size={20} stroke={1} />
                    </UnstyledButton>
                  </Group>
                )}
              </Group>
            </Grid.Col>
            <Grid.Col span={6}>
              <Grid align="center">
                <Grid.Col offset={4} span={4} style={{ textAlign: 'center' }}>
                  <Text size="sm">27</Text>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Switch
                    color="teal"
                    checked={subTag.is_active}
                    onChange={(event) => {
                      handleStatusChange(event.currentTarget.checked);
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </Box>
        <UnstyledButton
          style={{
            width: 20,
            visibility: tagSpec?.subTags.length === 1 ? 'hidden' : 'visible',
          }}
          size="sm"
        >
          <IconTrash
            size={20}
            onClick={() => deleteTagSpec(tagId, subTag.value)}
          />
        </UnstyledButton>
      </Box>
    );
  };

  const items = tagSpecs.map((item, index) => (
    <Accordion.Item key={index} value={item.id.toString()}>
      <Accordion.Control pr={0} style={{ background: 'white' }}>
        <AccordionLabel tagData={item} />
      </Accordion.Control>
      <Accordion.Panel pr={0}>
        <Button
          onClick={() => handleAddSubTagModal.open()}
          mb="sm"
          size="xs"
          variant="link"
        >
          Add Sub-Tag
        </Button>
        {item.subTags.map((subTag, index) => {
          return (
            <SubTagPanel
              key={index}
              subTag={subTag}
              tagId={item.id}
              updateTagSpec={updateTagSpec}
            />
          );
        })}
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <Box>
      <Flex mb="md" justify="space-between" align="center"></Flex>
      <Accordion
        value={expandedItems}
        multiple={true}
        chevron={false}
        variant="contained"
      >
        {items}
      </Accordion>
      <AddSubTagModal
        open={openAddSubTagModal}
        handleModal={handleAddSubTagModal}
        handleAddEditTagModal={handleAddEditTagModal}
        onCloseCallback={refreshList}
      />
    </Box>
  );
};

export default ListTagSpecs;
