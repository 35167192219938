import { request } from './httpService';

const API_VERSION = 'v1';
const pathPrefix = `/api/${API_VERSION}/`;

const CommonService = {
  getMetaContentTypes: async () => {
    return request({
      url: `${pathPrefix}meta/content-types/`,
      method: 'get',
    });
  },
};

export default CommonService;
