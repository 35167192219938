import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Group,
  Text,
  Card,
  Divider,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import RuleService from 'Api/ruleService';
import IconDocument from 'Components/icons/IconDocument';
import LoadingOverlay from 'Components/loading-overlay/LoadingOverlay';
import { RuleEvalStatus } from 'Constants/index';
import { RootState } from 'Src/redux/store';
import { CommentType } from 'Types/commentTypes';
import { RuleEvalType, SelectStatusProps } from 'Types/ruleTypes';
import {
  showErrorNotification,
  showInfoNotification,
} from 'Utils/notifications';

import Details from './Details';
import Header from './Header';

type RuleEvalCardProps = {
  ruleEval: RuleEvalType;
  snapshotId?: number;
  commentThread: CommentType;
  refreshAssessmentDetail?: () => void;
  viewMode?: boolean;
  isHighlighted?: boolean;
  refreshComments?: (snapshotId: number) => Promise<void>;
};

const RuleUnavailable = () => {
  return (
    <Card padding="0" withBorder={false}>
      <Group>
        <IconDocument color={'gray'} />
        <Text size="sm">Rule Unavailable</Text>
      </Group>
      <Divider mt="md" />
    </Card>
  );
};

const RuleEvalCard: React.FC<RuleEvalCardProps> = ({
  commentThread,
  ruleEval,
  refreshAssessmentDetail,
  snapshotId,
  viewMode = false,
  isHighlighted = false,
  refreshComments,
}) => {
  const [ruleEvalLoading, setRuleEvalLoading] = useState<boolean>(false);
  const [
    openStatusPrompt,
    { close: closeConfirmRetriggerModal, open: confirmRetriggerRule },
  ] = useDisclosure(false);
  const [cardOpened, { toggle: toggleCard }] = useDisclosure(false);
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const user = useSelector((state: RootState) => state.auth.user);
  const discussion = ruleEval?.manual_status_discussion;
  const latestComment =
    Array.isArray(discussion?.latest_posts) &&
    discussion.latest_posts.length > 0
      ? discussion.latest_posts[0]?.content
      : null;

  const onSelectStatus = async ({
    status,
    newComment,
    onCallback,
  }: SelectStatusProps) => {
    if (!ruleEval || !status) return;
    if (ruleEval.toString() === status) return;
    setRuleEvalLoading(true);
    try {
      if (newComment) {
        const commentDiscussion = {
          title: `${ruleEval.rule.title} is set to ${RuleEvalStatus[status as keyof typeof RuleEvalStatus]} by ${user?.username}`,
          posts: [
            {
              content: newComment,
              parent_post_id: discussion?.id || null,
            },
          ],
        };

        const { data } = await RuleService.updateRuleEvalStatus(ruleEval.id, {
          manual_status: parseInt(status),
          manual_status_discussion: commentDiscussion,
        });
        if (data) {
          showInfoNotification(
            'Rule Updated',
            'Rule evaluation status updated successfully'
          );
          refreshAssessmentDetail?.();
          if (snapshotId) await refreshComments?.(snapshotId);
          if (onCallback) onCallback();
        } else {
          throw new Error('Error while updating rule evaluation status');
        }
      }
    } catch (e) {
      showErrorNotification(
        'Something went wrong while update rule evaluation status'
      );
    }
    setRuleEvalLoading(false);
  };

  const reTriggerRule = async () => {
    closeConfirmRetriggerModal();
    if (!ruleEval) return;
    setRuleEvalLoading(true);
    const { data } = await RuleService.triggerRuleEvaluation(
      ruleEval?.id,
      true
    );
    if (data) {
      refreshAssessmentDetail?.();
    }
    setRuleEvalLoading(false);
  };

  const handleNavigateToComment = () => {
    if (commentThread) {
      const searchParams = new URLSearchParams(location.search);
      const currentCommentId = searchParams.get('commentId');

      // Clear existing params
      searchParams.delete('commentId');
      searchParams.delete('ruleEvalId');

      // Set the comment ID
      searchParams.set('commentId', commentThread.id.toString());

      // If navigating to the same comment, force a refresh by adding a timestamp
      if (currentCommentId === commentThread.id.toString()) {
        searchParams.set('t', Date.now().toString());
        navigate(`${location.pathname}?${searchParams.toString()}`);

        searchParams.delete('t');
        const cleanUrl = `${location.pathname}?${searchParams.toString()}`;
        window.history.replaceState({}, '', cleanUrl);
      } else navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  return (
    <Box key={ruleEval?.id} pos="relative">
      <LoadingOverlay
        visible={ruleEvalLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      {ruleEval ? (
        <Card
          padding="0"
          withBorder={false}
          style={{
            backgroundColor: isHighlighted
              ? theme.colors.yellow[1]
              : 'transparent',
          }}
        >
          <Header
            currentRuleEval={ruleEval}
            onSelectStatus={onSelectStatus}
            toggleCard={toggleCard}
            cardOpened={cardOpened}
            viewMode={viewMode}
          />
          <Details
            cardOpened={cardOpened}
            currentRuleEval={ruleEval}
            onSelectStatus={onSelectStatus}
            confirmRetriggerRule={confirmRetriggerRule}
            reTriggerRule={reTriggerRule}
            openStatusPrompt={openStatusPrompt}
            closeConfirmRetriggerModal={closeConfirmRetriggerModal}
            latestComment={latestComment}
            viewMode={viewMode}
            handleNavigateToComment={handleNavigateToComment}
          />
          <Divider mt="sm" />
        </Card>
      ) : (
        <RuleUnavailable />
      )}
    </Box>
  );
};

export default RuleEvalCard;
