import { kebabCase } from 'lodash';
import React, { useState, useEffect } from 'react';

import {
  TextInput,
  Button,
  Flex,
  Title,
  Group,
  Box,
  Modal,
  useMantineTheme,
  Grid,
  Stack,
  UnstyledButton,
  ScrollArea,
  Text,
  Autocomplete,
} from '@mantine/core';
import { IconCirclePlus } from '@tabler/icons-react';
import TagService from 'Api/tagService';
import { SubTagType, TagSpecType } from 'Types/tagTypes';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'Utils/notifications';

type AddEditTagModalProps = {
  open: boolean;
  handleModal: { close: () => void };
  handleAddEditTagModal: { open: () => void; close: () => void };
  onCloseCallback?: () => void;
  currentTagId?: string;
};

const SubTagChips: React.FC<{ subTagValue: string }> = ({ subTagValue }) => (
  <Box
    style={{
      background: 'white',
      border: '1px solid black',
      padding: '3px 10px',
    }}
    key={kebabCase(subTagValue)}
  >
    <Group gap="xs" align="center">
      <Text size="sm">{subTagValue}</Text>
      {/* <CloseButton size="sm" onClick={onClose} /> */}
    </Group>
  </Box>
);

const AddSubTagModal: React.FC<AddEditTagModalProps> = ({
  open,
  handleModal,
  handleAddEditTagModal,
  onCloseCallback,
  currentTagId,
}) => {
  const [subTags, setSubTags] = useState<SubTagType[]>([]);
  const [currentSubTag, setCurrentSubTag] = useState<string>('');
  const [subTagError, setSubTagError] = useState<string | null>(null);
  const [selectedTagSpec, setSelectedTagSpec] = useState<TagSpecType | null>(
    null
  );
  const [tagSpecs, setTagSpecs] = useState<TagSpecType[]>([]);
  const [searchKey, setSearchKey] = useState('');
  const theme = useMantineTheme();

  useEffect(() => {
    fetchTagSpecs();
    resetValues();
  }, [open]);

  useEffect(() => {
    if (tagSpecs) {
      setSelectedTagSpec(
        tagSpecs.find(({ id }) => id.toString() === currentTagId) || null
      );
    }
  }, [tagSpecs, currentTagId]);

  useEffect(() => {
    if (selectedTagSpec) {
      const selectedSubTag = selectedTagSpec.constraints.allowed_values;
      if (selectedSubTag) {
        setSubTags(selectedSubTag);
      }
    }
  }, [selectedTagSpec]);

  const fetchTagSpecs = async () => {
    try {
      const { data } = await TagService.getTagSpecs();
      if (data) {
        if (data.results.length > 0) {
          setTagSpecs(data.results);
        }
      } else {
        throw new Error('Error while fetching tag specs');
      }
    } catch (error: any) {
      showErrorNotification(error.message || 'Error while fetching tag specs');
    }
  };

  const resetValues = () => {
    setSearchKey('');
    setSubTags([]);
  };

  const updateTagSpec = async () => {
    try {
      if (selectedTagSpec === null)
        return showErrorNotification('Please select a tag');
      const tagSpec = {
        ...selectedTagSpec,
        constraints: { allowed_values: subTags },
      };
      await TagService.updateTagSpecs(selectedTagSpec.id, tagSpec);
      showSuccessNotification('Sub Tag added successfully');
      resetValues();
      handleModal.close();
      onCloseCallback?.();
    } catch (e: any) {
      showErrorNotification(e?.message || 'Error while saving tag');
    }
  };

  const appendSubTags = () => {
    if (!currentSubTag.trim()) return setSubTagError('Sub Tag cannot be empty');
    if (subTags.map(({ value }) => value).includes(currentSubTag))
      return setSubTagError('Sub Tag already exists');

    setSubTags((prevSubTags) => [
      ...prevSubTags,
      { value: currentSubTag, is_active: true },
    ]);
    setCurrentSubTag('');
    setSubTagError(null);
  };

  return (
    <Modal
      size="xl"
      styles={{
        content: {
          borderLeft: `9px solid ${theme.colors.primary[6]}`,
          padding: 20,
        },
      }}
      opened={open}
      onClose={() => {
        resetValues();
        handleModal.close();
      }}
      title={
        <Flex justify="space-between">
          <Title order={4}>
            Add Sub <strong>Tag</strong>
          </Title>
        </Flex>
      }
    >
      <Grid>
        <Grid.Col span={6}>
          <Stack gap="xs">
            <Autocomplete
              value={searchKey}
              onChange={setSearchKey}
              label="Select Tag"
              placeholder="Search by tag"
              onOptionSubmit={(value) => {
                setSelectedTagSpec(
                  tagSpecs.find(({ id }) => id.toString() === value) || null
                );
              }}
              data={tagSpecs.map((tag: TagSpecType) => ({
                label: tag.label,
                value: tag.id.toString(),
              }))}
              size="sm"
            />
            <Box>
              <Button
                variant="link"
                size="sm"
                onClick={() => {
                  handleModal.close();
                  handleAddEditTagModal.open();
                }}
              >
                + Create New
              </Button>
            </Box>
          </Stack>
        </Grid.Col>
        <Grid.Col span={6}>
          <Stack>
            <TextInput
              rightSection={
                <UnstyledButton
                  disabled={!currentSubTag}
                  mt={10}
                  onClick={appendSubTags}
                >
                  <IconCirclePlus
                    strokeWidth={1}
                    color={theme.colors.primary[6]}
                    size={34}
                  />
                </UnstyledButton>
              }
              value={currentSubTag}
              name="label"
              label="Add a Sub Tag (optional)"
              placeholder="Tag Name"
              onChange={(event) => setCurrentSubTag(event.currentTarget.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && currentSubTag) {
                  appendSubTags();
                }
              }}
              size="sm"
              error={subTagError}
            />
            <ScrollArea p="sm" bg="#e2e2e2" h={250} offsetScrollbars>
              <Group gap={10}>
                {subTags.map((subTag) => {
                  return (
                    <SubTagChips
                      key={kebabCase(subTag.value)}
                      subTagValue={subTag.value}
                    />
                  );
                })}
              </Group>
            </ScrollArea>
            <Flex justify="flex-end">
              <Button
                size="sm"
                variant="filled-shadow"
                onClick={updateTagSpec}
                mt="lg"
                disabled={
                  selectedTagSpec?.constraints.allowed_values.length ===
                  subTags.length
                }
              >
                Add
              </Button>
            </Flex>
          </Stack>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default AddSubTagModal;
