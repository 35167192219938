import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  Flex,
  Tabs,
  Text,
  Button,
  ActionIcon,
  Group,
  TextInput,
  Skeleton,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconCircleCheck,
  IconCircleCheckFilled,
  IconMessageCircle,
  IconSend,
  IconCornerUpRightDouble,
} from '@tabler/icons-react';
import { CommentThreadsService } from 'Api/commentThreadsService';
import LoadingOverlay from 'Components/loading-overlay/LoadingOverlay';
import { CommentStatus } from 'Constants/comments';
import useLoading from 'Src/hooks/useLoading';
import { RootState } from 'Src/redux/store';
import { CommentStatusType, CommentType, PostType } from 'Types/commentTypes';
import { SnapshotAnnotationType } from 'Types/docTypes';
import { transformDateString } from 'Utils/transform';

const FILTER_TYPES = {
  ALL: 'all',
  RULE_LEVEL: 'rule_level',
  DOCUMENT_LEVEL: 'document_level',
} as const;

type FilterType = (typeof FILTER_TYPES)[keyof typeof FILTER_TYPES];

const formatTabName = (key: string) => {
  return key
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const CommentsTab: React.FC = () => {
  const documentData = useSelector(
    (state: RootState) => state.activeDocument.data
  );
  const [comments, setComments] = useState<CommentType[]>([]);
  const [filteredComments, setFilteredComments] = useState<CommentType[]>([]);
  const [activeTab, setActiveTab] = useState<FilterType>(FILTER_TYPES.ALL);
  const [loading, handleLoading] = useLoading(true);
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const commentRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const [activeComment, setActiveComment] = useState<string | null>(null);
  const tabContentRef = useRef<HTMLDivElement>(null);

  const fetchComments = useCallback(async () => {
    handleLoading.start();
    const response = await CommentThreadsService.getCommentThreads({
      filters: {
        document_snapshot_id: documentData?.favorite_snapshot.id,
      },
    });
    if (response?.data) {
      setComments(response.data.results);
      setFilteredComments(response.data.results);
    }
    handleLoading.stop();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const scrollToComment = useCallback((commentId: number) => {
    setTimeout(() => {
      const commentElement = commentRefs.current[commentId];
      if (commentElement && tabContentRef.current) {
        const tabContentRect = tabContentRef.current.getBoundingClientRect();
        const commentRect = commentElement.getBoundingClientRect();
        tabContentRef.current.scrollTop =
          commentRect.top -
          tabContentRect.top +
          tabContentRef.current.scrollTop;
      }
    }, 100);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const commentId = searchParams.get('commentId');
    setActiveComment(commentId);
    if (commentId && filteredComments.length > 0) {
      scrollToComment(Number(commentId));
    }
  }, [location.search, filteredComments, scrollToComment]);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = comments;

      switch (activeTab) {
        case FILTER_TYPES.ALL:
          // No additional filtering needed
          break;
        case FILTER_TYPES.RULE_LEVEL:
          filtered = comments.filter((comment) => comment.rule_eval_id);
          break;
        case FILTER_TYPES.DOCUMENT_LEVEL:
          filtered = comments.filter((comment) => comment.document_snapshot_id);
          break;
      }

      // Apply search term filter
      if (searchTerm) {
        filtered = filtered.filter(
          (comment) =>
            comment.discussion.latest_posts[0].content
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            (comment.discussion.title &&
              comment.discussion.title
                .toLowerCase()
                .includes(searchTerm.toLowerCase()))
        );
      }

      setFilteredComments(filtered);
    };

    applyFilters();
  }, [activeTab, comments, documentData?.id, searchTerm]);

  useEffect(() => {
    fetchComments();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const handleOnResolve = useCallback(
    async (id: number, status: CommentStatusType) => {
      const currentCommentState = [...comments];
      try {
        const response = await CommentThreadsService.updateCommentThreads(id, {
          status,
        });

        if (response.data) {
          if (response.data.status === CommentStatus.RESOLVED) {
            setFilteredComments((prevFilteredComments) =>
              prevFilteredComments.filter((comment) => comment.id !== id)
            );
          }
        }
      } catch (error) {
        setComments(currentCommentState);
        setFilteredComments(currentCommentState);
      }
    },
    [comments]
  );

  return (
    <Box>
      <div className="comments-header">
        <Tabs
          defaultValue={FILTER_TYPES.ALL}
          onChange={(value) => setActiveTab(value as FilterType)}
        >
          <Tabs.List grow style={{ background: '#f1f3f5' }}>
            {Object.entries(FILTER_TYPES).map(([key, value]) => (
              <Tabs.Tab key={value} value={value}>
                {formatTabName(key)}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          <Box
            ref={tabContentRef}
            style={{
              height: 'calc(100vh - 220px)',
              overflowY: 'scroll',
              position: 'relative',
            }}
          >
            {loading && (
              <Box px="lg">
                {Array.from({ length: 5 }).map((_, index) => (
                  <Skeleton height={50} mt={'lg'} width="100%" key={index} />
                ))}
              </Box>
            )}
            {!loading && (
              <Tabs.Panel value={activeTab}>
                {activeTab === FILTER_TYPES.RULE_LEVEL && (
                  <Autocomplete
                    p="md"
                    placeholder="Search by rule name"
                    data={comments
                      .filter((comment) => comment.rule_eval_id)
                      .map((comment) => comment?.rule_eval?.rule?.title || '')
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      )}
                    value={searchTerm}
                    onChange={setSearchTerm}
                  />
                )}
                <Box pb={100}>
                  {filteredComments.map((comment) => (
                    <CommentsCard
                      key={comment.id}
                      comment={comment}
                      onResolve={handleOnResolve}
                      ref={(el) => (commentRefs.current[comment.id] = el)}
                      activeComment={activeComment}
                      scrollToComment={scrollToComment}
                    />
                  ))}
                  {!loading && filteredComments.length === 0 && (
                    <Box p="lg">
                      <Text>No comments found</Text>
                    </Box>
                  )}
                </Box>
              </Tabs.Panel>
            )}
          </Box>
        </Tabs>
      </div>
    </Box>
  );
};

export default CommentsTab;

interface CommentsCardProps {
  comment: CommentType;
  onResolve: (id: number, status: CommentStatusType) => void;
  scrollToComment: (commentId: number) => void;
  activeComment: string | null;
}

const CommentsCard = React.forwardRef<HTMLDivElement, CommentsCardProps>(
  ({ comment, onResolve, activeComment }, ref) => {
    const [isReplying, setIsReplying] = useState(false);
    const [replyText, setReplyText] = useState('');
    const [showReplies, setShowReplies] = useState(false);
    const [localReplies, setLocalReplies] = useState<PostType[]>([]);
    const [updatingComment, handleUpdatingComment] = useDisclosure(false);
    const navigate = useNavigate();
    const location = useLocation();

    const discussion = comment.discussion.latest_posts;
    const mainPost =
      discussion.length > 1 ? discussion[discussion.length - 1] : discussion[0];
    const replies = discussion.length > 1 ? discussion.slice(0, -1) : [];

    useEffect(() => {
      setLocalReplies(replies);
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [comment.discussion.latest_posts]);

    const fetchCommentThread = async () => {
      handleUpdatingComment.open();
      try {
        const response = await CommentThreadsService.getCommentThreadsById(
          comment.id
        );
        if (response?.data) {
          const newReplies = response.data.discussion.latest_posts;
          setLocalReplies(newReplies.length > 1 ? newReplies.slice(0, -1) : []);
        }
      } catch (e) {
        console.error('Failed to fetch comment thread:', e);
      } finally {
        handleUpdatingComment.close();
      }
    };

    const handleReplySubmit = useCallback(async () => {
      if (replyText.trim()) {
        const newReply: PostType = {
          id: Date.now(),
          discussion_id: comment.discussion.id,
          parent_post_id: mainPost.id,
          content: replyText,
          created_at: new Date().toISOString(),
          created_by: { username: 'CurrentUser' },
        };
        try {
          handleUpdatingComment.open();
          const response = await CommentThreadsService.addPost(
            comment.id,
            newReply
          );
          // Optimistic update and fetch new replies
          setLocalReplies((prevReplies) => [...prevReplies, response.data]);
          fetchCommentThread();
          setReplyText('');
          setIsReplying(false);
          setShowReplies(true);
        } catch (error) {
          console.error('Failed to add reply:', error);
        } finally {
          handleUpdatingComment.close();
        }
      }
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [replyText, comment.id, comment.discussion.id, mainPost.id]);

    const handleResolve = useCallback(async () => {
      try {
        handleUpdatingComment.open();
        await onResolve(comment.id, CommentStatus.RESOLVED);
      } catch (error) {
        console.error('Failed to resolve comment:', error);
      } finally {
        handleUpdatingComment.close();
      }
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [comment.id, onResolve]);

    const handleRedirection = (comment: CommentType) => {
      const searchParams = new URLSearchParams(location.search);

      // Remove existing parameters
      searchParams.delete('annotationId');
      searchParams.delete('commentId');
      searchParams.delete('ruleEvalId');

      if (comment.rule_eval_id) {
        searchParams.set('ruleEvalId', comment.rule_eval_id.toString());
      } else if (comment.annotations && comment.annotations.length > 0) {
        const annotation = comment
          .annotations[0] as unknown as SnapshotAnnotationType;
        const annotationXml = annotation?.annotation_xml;

        const nameMatch = annotationXml.match(/name="([^"]*?)"/);
        const annotationId = nameMatch ? nameMatch[1] : null;

        if (annotationId) {
          searchParams.set('annotationId', annotationId);
        }
      } else {
        console.error('No annotations or rule_eval_id found for this comment');
        return; // Exit the function if no valid redirection is possible
      }

      // Construct the new URL and navigate
      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      navigate(newUrl, { replace: true });
    };

    return (
      <Box
        ref={ref}
        p="sm"
        px="lg"
        className="comment-card"
        style={{
          borderBottom: '1px solid #DADADA',
          backgroundColor:
            activeComment === comment.id.toString() ? '#FFFFD1' : 'transparent',
        }}
        pos={'relative'}
      >
        {updatingComment && <LoadingOverlay visible={updatingComment} />}
        <Flex mb="sm" justify="space-between" align="center" gap={4}>
          <Text fw={500} size="sm">
            {comment?.discussion.title && comment?.discussion.title !== ''
              ? comment.discussion.title
              : mainPost.created_by.username}
          </Text>
          <Flex gap="0">
            {comment.status === CommentStatus.RESOLVED ? (
              <ActionIcon c="#429103" variant="subtle">
                <IconCircleCheckFilled size={25} />
              </ActionIcon>
            ) : (
              <ActionIcon
                c="#D6DAD2"
                variant="transparent"
                onClick={handleResolve}
              >
                <IconCircleCheck size={25} />
              </ActionIcon>
            )}
            <Tooltip
              label={
                comment?.rule_eval_id ? 'Go to rule eval' : 'Go to annotation'
              }
            >
              <ActionIcon
                c="#D6DAD2"
                variant="transparent"
                onClick={() => handleRedirection(comment)}
              >
                <IconCornerUpRightDouble
                  size={30}
                  style={{ transform: 'scaleX(-1)' }}
                />
              </ActionIcon>
            </Tooltip>
          </Flex>
        </Flex>
        <Text fw={100} size="sm">
          {mainPost.content}
        </Text>
        <Flex justify="space-between" align="center" mt="sm" gap={4}>
          <Group align="center" gap="xs" mb="sm">
            {!showReplies && (
              <Button
                size="sm"
                variant="link"
                onClick={() => setIsReplying(!isReplying)}
              >
                {isReplying ? 'Cancel' : 'Reply'}
              </Button>
            )}
            {localReplies.length > 0 &&
              (!showReplies ? (
                <ActionIcon
                  variant="subtle"
                  onClick={() => setShowReplies(true)}
                >
                  <IconMessageCircle size={20} />
                  <Text size="xs" ml={4}>
                    {localReplies.length}
                  </Text>
                </ActionIcon>
              ) : (
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => setShowReplies(false)}
                >
                  Hide Replies
                </Button>
              ))}
          </Group>
          <Text size="xs">
            {transformDateString(mainPost.created_at, true, true)}
          </Text>
        </Flex>

        {showReplies && localReplies.length > 0 && (
          <Flex
            align="flex-end"
            direction="column"
            gap="lg"
            style={{ background: '#f1f3f5' }}
            mx={-15}
            pl={35}
            pr={15}
            py={20}
          >
            {localReplies.map((reply) => (
              <ReplyBubble key={reply.id} reply={reply} />
            ))}
          </Flex>
        )}

        {(isReplying || showReplies) && (
          <Group mt="sm">
            <TextInput
              placeholder="Type your reply..."
              value={replyText}
              onChange={(event) => setReplyText(event.currentTarget.value)}
              style={{ flex: 1 }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleReplySubmit();
                }
              }}
            />
            <ActionIcon variant="transparent" onClick={handleReplySubmit}>
              <IconSend size={25} />
            </ActionIcon>
          </Group>
        )}
      </Box>
    );
  }
);

const ReplyBubble: React.FC<{ reply: PostType }> = ({ reply }) => {
  return (
    <Box
      ml="lg"
      style={{
        backgroundColor: 'white',
        borderRadius: '0 12px 12px 12px',
        padding: '8px 12px',
        width: '100%',
        border: '1px solid #DADADA',
      }}
    >
      <Text fw={500} size="sm">
        @{reply.created_by.username}
      </Text>
      <Text fw={100} size="sm">
        {reply.content}
      </Text>
      <Flex justify="flex-end" align="center" mt="sm" gap={4}>
        <Text size="xs" c="dimmed" mt={4}>
          {transformDateString(reply.created_at, true, true)}
        </Text>
      </Flex>
    </Box>
  );
};
