import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';

import { Skeleton, Box, Text } from '@mantine/core';
import { CommentThreadsService } from 'Api/commentThreadsService';
import { CommentType } from 'Types/commentTypes';
import { RuleEvalType } from 'Types/ruleTypes';

import RuleEvalCard from '../components/assessment-tab/overview/rule-eval-card/RuleEvalCard';

type DisplayRuleEvalsProps = {
  loading: boolean;
  ruleEvals: RuleEvalType[];
  snapshotId: number | undefined;
  refreshAssessmentDetail?: () => void;
  viewMode?: boolean;
  ruleEvalRefs?: React.MutableRefObject<{
    [key: number]: HTMLDivElement | null;
  }>;
  setActiveTab?: (tab: string) => void;
};

const DisplayRuleEvals: React.FC<DisplayRuleEvalsProps> = ({
  loading,
  ruleEvals,
  snapshotId,
  refreshAssessmentDetail,
  viewMode,
  ruleEvalRefs,
}) => {
  const [ruleEvalCommentsMap, setRuleEvalCommentsMap] = useState<
    Record<string, CommentType>
  >({});
  const location = useLocation();
  const highlightedRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (snapshotId) fetchComments(snapshotId);
  }, [snapshotId]);

  const { isHighlighted } = useHighlightedRuleEval();

  useEffect(() => {
    if (highlightedRef.current) {
      highlightedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [location.search, ruleEvals]);

  const fetchComments = async (snapshotId: number) => {
    try {
      const response = await CommentThreadsService.getCommentThreads({
        filters: {
          document_snapshot: snapshotId,
        },
      });
      if (response?.data) {
        const { results = [] } = response.data;
        const ruleEvalMap = results.reduce(
          (acc: Record<string, CommentType>, item: CommentType) => {
            if (item.rule_eval_id && item.rule_eval) {
              acc[item.rule_eval_id] = item;
            }
            return acc;
          },
          {}
        );
        setRuleEvalCommentsMap(ruleEvalMap);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      {loading && (
        <Box>
          {Array.from({ length: 10 }).map((_, index) => {
            return <Skeleton height={60} mt={'lg'} width="100%" key={index} />;
          })}
        </Box>
      )}
      {ruleEvals.length === 0 ? (
        <Text size="sm">No Assessment found</Text>
      ) : (
        ruleEvals.map((item: RuleEvalType, index: number) => {
          const highlighted = isHighlighted(item.id.toString());
          return (
            <Box
              key={index}
              ref={(el) => {
                if (ruleEvalRefs && ruleEvalRefs.current) {
                  ruleEvalRefs.current[item.id] = el;
                }
                if (highlighted && el) {
                  highlightedRef.current = el;
                }
              }}
            >
              <RuleEvalCard
                ruleEval={item}
                viewMode={viewMode}
                commentThread={
                  ruleEvalCommentsMap && ruleEvalCommentsMap[item?.id]
                }
                snapshotId={snapshotId}
                refreshAssessmentDetail={refreshAssessmentDetail}
                isHighlighted={highlighted}
                refreshComments={fetchComments}
              />
            </Box>
          );
        })
      )}
    </React.Fragment>
  );
};

export default DisplayRuleEvals;

const useHighlightedRuleEval = () => {
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const highlightedRuleEvalId = searchParams.get('ruleEvalId');

  const isHighlighted = useCallback(
    (itemId: string) => highlightedRuleEvalId === itemId.toString(),
    [highlightedRuleEvalId]
  );

  return { isHighlighted, highlightedRuleEvalId, searchParams };
};
