import {
  createTheme,
  MantineColorsTuple,
  isMantineColorScheme,
  MantineColorScheme,
  MantineColorSchemeManager,
  rem,
  Button,
  CSSVariablesResolver,
  Loader,
} from '@mantine/core';
import RingLoader from 'Components/ring-loader/RingLoader';
import { User } from 'Slices/authSlice';
import NaehasWhiteLogo from 'Src/assets/naehas-white-logo.png';

import classes from './components/theme.extend.module.scss';

// const zenColors: MantineColorsTuple = [
//   '#f7ebff',
//   '#e6d5fb',
//   '#c8a9f2',
//   '#aa7aea',
//   '#9051e2',
//   '#8039de',
//   '#782bdd',
//   '#661ec5',
//   '#5a1ab1',
//   '#4e139c',
// ];

const naehasColors: MantineColorsTuple = [
  '#e8fcfd',
  '#d9f3f5',
  '#b4e5ea',
  '#8bd7de',
  '#6bcbd4',
  '#57c4ce',
  '#003D4C',
  '#39aab3',
  '#2997a0',
  '#01838c',
];

// const zenafideColors: MantineColorsTuple = [
//   '#ffeaf9',
//   '#fed4e8',
//   '#f4a7ce',
//   '#ed77b2',
//   '#e64f9a',
//   '#e2358b',
//   '#e12684',
//   '#c81871',
//   '#b30d65',
//   '#9f0058',
// ];

const pinkColors: MantineColorsTuple = [
  '#ffebf8',
  '#fad8e8',
  '#edadcd',
  '#e282b1',
  '#d85d99',
  '#d2458a',
  '#CD307D',
  '#b92970',
  '#a62164',
  '#931557',
];
const greyColors: MantineColorsTuple = [
  '#FFF',
  '#FFF',
  '#F8F8F8',
  '#F8F8F8',
  '#BBB',
  '#BBB',
  '#757575',
  '#757575',
  '#000',
  '#000',
];
const blueColors: MantineColorsTuple = [
  '#85939F',
  '#6B7C88',
  '#526571',
  '#384E5A',
  '#1F3743',
  '#091A29',
  '#081626',
  '#071222',
  '#060E1E',
  '#050A1B',
];
// const Naehastheme = createTheme({
//   focusRing: 'never',
//   // in css access this value by:
//   // background-color: var(--mantine-color-zen-blue-filled
//   primaryColor: 'primary',
//   colors: {
//     primary: naehasColors,
//   },
// });

const resolver: CSSVariablesResolver = () => ({
  variables: {
    '--mantine-color-anchor': '#232323',
  },
  light: {
    '--mantine-color-anchor': '#232323',
  },
  dark: {
    '--mantine-color-anchor': '#fff',
  },
});
const NaehasTheme = createTheme({
  scale: 1,
  focusRing: 'never',
  primaryColor: 'primary',
  fontFamily: 'Figtree',
  defaultRadius: 4,
  /** Determines which cursor type will be used for interactive elements
   * - `default` – cursor that is used by native HTML elements, for example, `input[type="checkbox"]` has `cursor: default` styles
   * - `pointer` – sets `cursor: pointer` on interactive elements that do not have these styles by default
   */
  cursorType: 'pointer',
  colors: {
    primary: naehasColors,
    secondary: greyColors,
    tertiary: blueColors,
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '1rem',
    md: '1.25rem',
    lg: '1.5rem',
    xl: '1.75rem',
  },
  headings: {
    fontWeight: '400',
    sizes: {
      h1: {
        fontSize: rem(35),
        lineHeight: 'normal',
      },
      h2: { fontSize: rem(30), lineHeight: 'normal' },
      h3: { fontSize: rem(25), lineHeight: 'normal' },
      h4: { fontSize: rem(20), lineHeight: 'normal', fontWeight: '500' },
      h5: { fontSize: rem(18), lineHeight: 'normal', fontWeight: '500' },
      h6: { fontSize: rem(16), lineHeight: 'normal' },
    },
  },
  components: {
    Button: Button.extend({
      classNames: classes,
    }),
    Loader: Loader.extend({
      defaultProps: {
        loaders: { ...Loader.defaultLoaders, ring: RingLoader },
        type: 'ring',
      },
    }),
  },
});

const ZenafideTheme = createTheme({
  scale: 1,
  focusRing: 'never',
  primaryColor: 'primary',
  fontFamily: 'Figtree',
  defaultRadius: 4,
  /** Determines which cursor type will be used for interactive elements
   * - `default` – cursor that is used by native HTML elements, for example, `input[type="checkbox"]` has `cursor: default` styles
   * - `pointer` – sets `cursor: pointer` on interactive elements that do not have these styles by default
   */
  cursorType: 'pointer',
  colors: {
    primary: pinkColors,
    secondary: greyColors,
    tertiary: blueColors,
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '1rem',
    md: '1.25rem',
    lg: '1.5rem',
    xl: '1.75rem',
  },
  headings: {
    fontWeight: '400',
    sizes: {
      h1: {
        fontSize: rem(35),
        lineHeight: 'normal',
      },
      h2: { fontSize: rem(30), lineHeight: 'normal' },
      h3: { fontSize: rem(25), lineHeight: 'normal' },
      h4: { fontSize: rem(20), lineHeight: 'normal', fontWeight: '500' },
      h5: { fontSize: rem(18), lineHeight: 'normal', fontWeight: '500' },
      h6: { fontSize: rem(16), lineHeight: 'normal' },
    },
  },
  components: {
    Button: Button.extend({
      classNames: classes,
    }),
    Loader: Loader.extend({
      defaultProps: {
        loaders: { ...Loader.defaultLoaders, ring: RingLoader },
        type: 'ring',
      },
    }),
  },
});

// console.log(Naehastheme, NaehasLogo);

const fetchTheme = (user: User | null) => {
  if (user && user?.email) {
    if (user.email.includes('pathward')) {
      return NaehasTheme;
    }
  }
  return ZenafideTheme;
};

const fetchLogo = (user?: User | null) => {
  if (user && user?.email) {
    if (user.email.toLowerCase().includes('iquanti')) {
      return NaehasWhiteLogo;
    }
  }
  return NaehasWhiteLogo;
};

export type LocalStorageColorSchemeManagerOptions = {
  /** Local storage key used to retrieve value with `localStorage.getItem(key)`, `mantine-color-scheme` by default */
  key?: string;
};

export function localStorageColorSchemeManager({
  key = 'mantine-color-scheme',
}: LocalStorageColorSchemeManagerOptions = {}): MantineColorSchemeManager {
  let handleStorageEvent: (event: StorageEvent) => void;

  return {
    get: (defaultValue) => {
      if (typeof window === 'undefined') {
        return defaultValue;
      }

      try {
        return (
          (window.localStorage.getItem(key) as MantineColorScheme) ||
          defaultValue
        );
      } catch {
        return defaultValue;
      }
    },

    set: (value) => {
      try {
        window.localStorage.setItem(key, value);
      } catch (error) {
        console.warn(
          '[@mantine/core] Local storage color scheme manager was unable to save color scheme.',
          error
        );
      }
    },

    subscribe: (onUpdate) => {
      handleStorageEvent = (event) => {
        if (event.storageArea === window.localStorage && event.key === key) {
          isMantineColorScheme(event.newValue) && onUpdate(event.newValue);
        }
      };

      window.addEventListener('storage', handleStorageEvent);
    },

    unsubscribe: () => {
      window.removeEventListener('storage', handleStorageEvent);
    },

    clear: () => {
      window.localStorage.removeItem(key);
    },
  };
}

export { fetchTheme, fetchLogo, resolver };
export default ZenafideTheme;
