import Markdown from 'react-markdown';
import { useSelector } from 'react-redux';

import { Text } from '@mantine/core';
import { RootState } from 'Src/redux/store';
import { MessageTypes } from 'Types/chatTypes';

import classes from './ChatMessage.module.scss';

type ChatMessageProps = MessageTypes & {
  timestamp?: Date;
};

const ResultType = {
  MARKDOWN: 'markdown',
  LIST: 'list',
  BOOLEAN: 'boolean',
};

const ChatMessage: React.FC<ChatMessageProps> = ({
  sender,
  data,
  resultType,
}) => {
  const user = useSelector((state: RootState) => state.auth.user);

  const renderContent = () => {
    switch (resultType) {
      case ResultType.MARKDOWN:
        return <Markdown>{data}</Markdown>;
      case ResultType.LIST:
        if (Array.isArray(data)) {
          return (
            <ul className={classes.bulletList}>
              {data.map((item, index) => (
                <li key={index}>{item.toString()}</li>
              ))}
            </ul>
          );
        }
        return data?.toString() || data;
      default:
        return data?.toString() || data;
    }
  };

  if (!user) return null;

  return (
    <div className={`${classes.chatMessage} ${classes[sender]}`}>
      <Text size="sm">{renderContent()}</Text>
    </div>
  );
};

export default ChatMessage;
