import { kebabCase } from 'lodash';
import React, { useState, useEffect } from 'react';

import {
  TextInput,
  Button,
  Flex,
  Title,
  Group,
  Box,
  Modal,
  useMantineTheme,
  Grid,
  Stack,
  UnstyledButton,
  ScrollArea,
  CloseButton,
  Text,
} from '@mantine/core';
import { IconCirclePlus } from '@tabler/icons-react';
import TagService from 'Api/tagService';
import { SubTagType } from 'Types/tagTypes';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'Utils/notifications';

type AddEditTagModalProps = {
  open: boolean;
  handleDrawer: { close: () => void };
  onCloseCallback?: () => void;
  isEditMode?: boolean;
  tagData?: {
    id: number;
    label: string;
    subTags: SubTagType[];
  } | null;
};

const DEFAULT_SUB_TAG_DATA_TYPE = 'string';

const SubTagChips: React.FC<{ subTagValue: string; onClose: () => void }> = ({
  subTagValue,
  onClose,
}) => (
  <Box
    style={{
      background: 'white',
      border: '1px solid black',
      padding: '3px 10px',
    }}
    key={kebabCase(subTagValue)}
  >
    <Group gap="xs" align="center">
      <Text size="sm">{subTagValue}</Text>
      <CloseButton size="sm" onClick={onClose} />
    </Group>
  </Box>
);

const AddEditTagModal: React.FC<AddEditTagModalProps> = ({
  open,
  handleDrawer,
  onCloseCallback,
  isEditMode = false,
  tagData,
}) => {
  const [subTags, setSubTags] = useState<SubTagType[] | []>([]);
  const [tagLabel, setTagLabel] = useState<string>('');
  const [currentSubTag, setCurrentSubTag] = useState<string>('');
  const [subTagError, setSubTagError] = useState<string | null>(null);
  const theme = useMantineTheme();

  useEffect(() => {
    if (isEditMode && tagData) {
      setTagLabel(tagData.label);
      setSubTags(tagData.subTags);
    } else {
      resetValues();
    }
  }, [isEditMode, tagData]);

  const removeSubTagField = (key: string) => {
    setSubTags((prevSubTags) =>
      prevSubTags.filter(({ value }) => kebabCase(value) !== key)
    );
  };

  const resetValues = () => {
    setTagLabel('');
    setSubTags([]);
    setCurrentSubTag('');
    setSubTagError(null);
  };

  const saveTagSpec = async () => {
    try {
      if (subTags.length === 0)
        return setSubTagError('Add atleast one sub tag');
      const tagSpec = {
        label: tagLabel,
        data_type: DEFAULT_SUB_TAG_DATA_TYPE,
        constraints: { allowed_values: subTags },
      };
      if (isEditMode && tagData) {
        await TagService.updateTagSpecs(tagData.id, tagSpec);
        showSuccessNotification('Tag updated successfully');
      } else {
        await TagService.createTagSpecs(tagSpec);
        showSuccessNotification('Tag created successfully');
      }
      handleDrawer.close();
      onCloseCallback?.();
      resetValues();
    } catch (e: any) {
      showErrorNotification(e?.message || 'Error while saving tag');
    }
  };

  const appendSubTags = () => {
    if (!currentSubTag.trim()) return setSubTagError('Sub Tag cannot be empty');
    if (subTags.map(({ value }) => value).includes(currentSubTag))
      return setSubTagError('Sub Tag already exists');

    setSubTags((prevSubTags) => [
      ...prevSubTags,
      { is_active: true, value: currentSubTag },
    ]);
    setCurrentSubTag('');
    setSubTagError(null);
  };

  return (
    <Modal
      size="xl"
      styles={{
        content: {
          borderLeft: `9px solid ${theme.colors.primary[6]}`,
          padding: 20,
        },
      }}
      opened={open}
      onClose={() => {
        resetValues();
        handleDrawer.close();
      }}
      title={
        <Flex justify="space-between">
          <Title order={4}>
            {isEditMode ? 'Edit' : 'Create new'} <strong>Tag</strong>
          </Title>
        </Flex>
      }
    >
      <Grid>
        <Grid.Col span={6}>
          <TextInput
            value={tagLabel}
            name="label"
            label="Tag Name"
            placeholder="Add Tag Name"
            required
            onChange={(event) => setTagLabel(event.currentTarget.value)}
            size="sm"
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Stack>
            <TextInput
              rightSection={
                <UnstyledButton
                  disabled={!currentSubTag}
                  mt={10}
                  onClick={appendSubTags}
                >
                  <IconCirclePlus
                    strokeWidth={1}
                    color={theme.colors.primary[6]}
                    size={34}
                  />
                </UnstyledButton>
              }
              value={currentSubTag}
              name="label"
              label="Add a Sub Tag"
              placeholder="Tag Name"
              onChange={(event) => {
                setSubTagError(null);
                setCurrentSubTag(event.currentTarget.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && currentSubTag) {
                  appendSubTags();
                }
              }}
              size="sm"
              error={subTagError}
            />
            <ScrollArea p="sm" bg="#e2e2e2" h={250} offsetScrollbars>
              <Group gap={10}>
                {subTags.map(({ value }) => (
                  <SubTagChips
                    key={kebabCase(value)}
                    subTagValue={value}
                    onClose={() => removeSubTagField(kebabCase(value))}
                  />
                ))}
              </Group>
            </ScrollArea>
            <Flex justify="flex-end">
              <Button
                size="sm"
                variant="filled-shadow"
                onClick={saveTagSpec}
                mt="lg"
                disabled={!tagLabel}
              >
                {isEditMode ? 'Update' : 'Create'}
              </Button>
            </Flex>
          </Stack>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default AddEditTagModal;
