import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  Button,
  Flex,
  Group,
  Input,
  Title,
  Box,
  Text,
  Grid,
  ScrollArea,
  UnstyledButton,
  Menu,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import {
  IconArrowBarRight,
  IconChevronRight,
  IconPlus,
  IconSearch,
  IconTrash,
} from '@tabler/icons-react';
import TagService from 'Api/tagService';
import { TagSpecType, TransformedTagSpecType } from 'Types/tagTypes';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'Utils/notifications';
import { transformTagSpecData } from 'Utils/transform';

import AddEditTagModal from './AddEditTagModal';
import ListTagSpecs from './ListTagSpecs';

const TagManagement: React.FC = () => {
  const [tagSpecs, setTagSpecs] = useState<TransformedTagSpecType[]>([]);
  const [selectedTags, setSelectedTags] = useState<Set<string>>(new Set());
  const [searchValue, setSearchValue] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);

  // for add edit modal
  const [openAddEditTagModal, handleAddEditTagModal] = useDisclosure(false);

  const [isEditMode, setIsEditMode] = useState(false);
  const [tagDataForModal, setTagDataForModal] = useState(null);

  useEffect(() => {
    fetchTagSpecs();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === 'f') {
        event.preventDefault();
        searchInputRef.current?.focus();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const fetchTagSpecs = async () => {
    try {
      const { data } = await TagService.getTagSpecs();
      if (data && data.results) {
        const transformedData = transformTagSpecData(data.results);
        setTagSpecs(transformedData);
      } else {
        throw new Error('Error while fetching tag specs');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filteredTagSpecs = useMemo(() => {
    return tagSpecs.filter((tag) =>
      tag.searchableText.includes(searchValue.toLowerCase())
    );
  }, [searchValue, tagSpecs]);

  const removeSubTag = async (tagId: number, subTag: string) => {
    const tagSpec = tagSpecs.find(({ id }) => id === tagId);
    if (!tagSpec) return;

    const updatedSubTags = tagSpec.subTags.filter((tag) => {
      if (typeof tag === 'string') {
        return tag !== subTag;
      } else if (typeof tag === 'object' && tag !== null && 'value' in tag) {
        return tag.value !== subTag;
      }
      return true;
    });

    await updateTagSpec(tagId, {
      id: tagId,
      constraints: { allowed_values: updatedSubTags || [] },
    });
  };

  const confirmDeleteTagSpec = (tagId: number, subTagValue?: string) => {
    modals.open({
      title: 'Delete Snapshot',
      children: (
        <Box>
          <Text size="sm">
            Are you sure you want to permanently delete this Tag? <br />
            This action cannot be undone.
          </Text>
          <Flex style={{ width: '100%' }} align="center" justify="flex-end">
            <Group gap="sm">
              <Button
                variant="outline"
                size="compact-sm"
                onClick={async () => {
                  if (subTagValue) {
                    await removeSubTag(tagId, subTagValue);
                  } else {
                    await deleteTag(tagId);
                  }
                  modals.closeAll();
                }}
              >
                Yes I'm Sure
              </Button>
              <Button
                variant="subtle"
                size="compact-sm"
                onClick={modals.closeAll}
              >
                Cancel
              </Button>
            </Group>
          </Flex>
        </Box>
      ),
    });
  };

  const confirmBulkDeleteTagSpec = (tagSpecIds: number[]) => {
    modals.open({
      title: 'Delete Selected Snapshot',
      size: 'md',
      children: (
        <Box>
          <Text size="sm">
            Are you sure you want to permanently delete all the Tags? <br />
            All the subtags will be deleted as well <br />
            This action cannot be undone.
          </Text>
          <Flex style={{ width: '100%' }} align="center" justify="flex-end">
            <Group gap="sm">
              <Button
                variant="outline"
                size="compact-sm"
                onClick={async () => {
                  try {
                    await TagService.bulkDeleteTagSpecs(tagSpecIds);
                    fetchTagSpecs();
                    showSuccessNotification('Tags deleted successfully');
                  } catch (error) {
                    showErrorNotification(
                      'Error while deleting tag, Try Again later'
                    );
                  } finally {
                    modals.closeAll();
                  }
                }}
              >
                Yes I'm Sure
              </Button>
              <Button
                variant="subtle"
                size="compact-sm"
                onClick={modals.closeAll}
              >
                Cancel
              </Button>
            </Group>
          </Flex>
        </Box>
      ),
    });
  };

  const deleteTag = async (id: number) => {
    try {
      await TagService.deleteTagSpecs(id);
      fetchTagSpecs();
      showSuccessNotification('Tag deleted successfully');
    } catch (error) {
      showErrorNotification('Error while deleting tag');
      console.error(error);
    }
  };

  const updateTagSpec = async (
    tagId: number,
    tagData: Partial<TagSpecType>
  ) => {
    try {
      // optimistic update
      const updatedTagSpecs = tagSpecs.map((tag) =>
        tag.id === tagId ? { ...tag, ...tagData } : tag
      );
      setTagSpecs(updatedTagSpecs);
      await TagService.patchTagSpecs(tagId, tagData);
      await fetchTagSpecs();
      showSuccessNotification('Tag updated successfully');
    } catch (error) {
      showErrorNotification('Error while updating tag');
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Flex w="100%" direction="column">
        <Flex justify="space-between" align="center" mb="md">
          <Group>
            <Title order={4}>Tag Management</Title>
            <Button
              leftSection={<IconPlus size={12} />}
              onClick={() => {
                setIsEditMode(false);
                handleAddEditTagModal.open();
              }}
              size="xs"
              variant="outline"
            >
              Add New
            </Button>
          </Group>
        </Flex>
        <Box h="calc(100vh - 135px)" bg="white" p="md">
          <Grid align="center" mb="lg" pr={30}>
            <Grid.Col span={4}>
              <Input
                ref={searchInputRef}
                styles={{
                  input: {
                    backgroundColor: '#F6F5F8',
                  },
                }}
                placeholder="Search"
                leftSection={<IconSearch size={16} />}
                rightSectionWidth={45}
                rightSection={
                  <Group gap={2}>
                    <Text span size="md">
                      ⌘
                    </Text>
                    <Text span size="md">
                      F
                    </Text>
                  </Group>
                }
                value={searchValue}
                onChange={(event) => setSearchValue(event.currentTarget.value)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Grid align="center">
                <Grid.Col span={4} style={{ textAlign: 'center' }}>
                  <Text size="sm">Sub Tags</Text>
                </Grid.Col>
                <Grid.Col span={4} style={{ textAlign: 'center' }}>
                  <Text size="sm">Rules Tagged</Text>
                </Grid.Col>
                <Grid.Col span={4} style={{ textAlign: 'center' }}>
                  <Text size="sm">Status</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={2}>
              <Flex justify="flex-end">
                <Menu shadow="md" withArrow>
                  <Menu.Target>
                    <UnstyledButton>
                      <Group align="center" gap="xs">
                        <Text fw={600} size="sm">
                          Bulk Actions
                        </Text>
                        <IconChevronRight size="1rem" />
                      </Group>
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={() => alert('Not implemented')}
                      component="button"
                      leftSection={<IconArrowBarRight size={14} />}
                    >
                      Move
                    </Menu.Item>
                    <Menu.Item
                      color="red"
                      component="button"
                      leftSection={<IconTrash size={14} />}
                      onClick={() =>
                        confirmBulkDeleteTagSpec(
                          Array.from(selectedTags).map((tag) => Number(tag))
                        )
                      }
                    >
                      Delete
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Flex>
            </Grid.Col>
          </Grid>
          <ScrollArea h="calc(100vh - 210px)" pb="lg">
            <ListTagSpecs
              tagSpecs={filteredTagSpecs}
              setIsEditMode={setIsEditMode}
              setTagDataForModal={setTagDataForModal}
              handleAddEditTagModal={handleAddEditTagModal}
              deleteTagSpec={confirmDeleteTagSpec}
              updateTagSpec={updateTagSpec}
              refreshList={fetchTagSpecs}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
          </ScrollArea>
        </Box>
      </Flex>
      <AddEditTagModal
        open={openAddEditTagModal}
        handleDrawer={handleAddEditTagModal}
        isEditMode={isEditMode}
        tagData={tagDataForModal}
        onCloseCallback={() => {
          fetchTagSpecs();
        }}
      />
      {/* <TagDetailModal
        open={openAddEditTagModal}
        handleDrawer={handleAddEditTagModal}
        isEditMode={isEditMode}
        tagData={tagDataForModal}
        onCloseCallback={() => {
          fetchTagSpecs();
        }}
      /> */}
    </React.Fragment>
  );
};

export default TagManagement;
