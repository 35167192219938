import { SetStateAction, useEffect, useState, Dispatch } from 'react';

import {
  PillsInput,
  Pill,
  Combobox,
  useCombobox,
  Checkbox,
  Box,
  Stack,
} from '@mantine/core';
import { TagAssociationType, TransformedTagSpecType } from 'Types/tagTypes';

interface NestedDropdownProps {
  associatedTags: TagAssociationType[] | [];
  setAddFormValues: Dispatch<SetStateAction<any>>;
  tagSpecs: TransformedTagSpecType[] | [];
}

const NestedDropdown: React.FC<NestedDropdownProps> = ({
  associatedTags = [],
  setAddFormValues,
  tagSpecs,
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [search, setSearch] = useState('');
  const [filteredTagSpecs, setFilteredTagSpecs] = useState<
    TransformedTagSpecType[]
  >([]);

  useEffect(() => {
    if (search.length > 0) {
      const filteredData = tagSpecs.filter(
        (item) =>
          item.label.toLowerCase().includes(search.toLowerCase()) ||
          item.subTags.some((subTag) => {
            return subTag.value.toLowerCase().includes(search.toLowerCase());
          })
      );
      setFilteredTagSpecs(filteredData);
    } else {
      setFilteredTagSpecs(tagSpecs);
    }
  }, [search, tagSpecs]);

  const addTags = (tagId: number, value: string) => {
    const doesTagExist = associatedTags.some(
      (tag) => tag.tag_spec_id === tagId && tag.value === value
    );
    const updatedAssociatedTags = doesTagExist
      ? associatedTags
      : [...associatedTags, { tag_spec_id: tagId, value }];
    setAddFormValues((prevFormValues: any) => {
      return { ...prevFormValues, tags: updatedAssociatedTags };
    });
  };

  // on main tag spec useClickOutside, call this function to add all the subtags
  const addTagSpec = (tagId: number) => {
    const subTagsToUpdate =
      tagSpecs.find((tag) => tag.id === tagId)?.subTags || [];
    const nonExistingTags = subTagsToUpdate
      .map((subTag) => ({ tag_spec_id: tagId, value: subTag.value }))
      .filter(
        (tag) =>
          !associatedTags.some(
            (existingTag) =>
              existingTag.tag_spec_id === tag.tag_spec_id &&
              existingTag.value === tag.value
          )
      );

    if (nonExistingTags.length > 0) {
      setAddFormValues((prevFormValues: any) => ({
        ...prevFormValues,
        tags: [...associatedTags, ...nonExistingTags],
      }));
    }
  };

  const removeTags = (tagId: number, value: string) => {
    const updatedAssociatedTags = associatedTags.filter(
      (tag) => !(tag.tag_spec_id === tagId && tag.value === value)
    );
    setAddFormValues((prevFormValues: any) => {
      return { ...prevFormValues, tags: updatedAssociatedTags };
    });
  };

  const removeTagSpec = (tagId: number) => {
    const updatedAssociatedTags = associatedTags.filter(
      (tag) => tag.tag_spec_id !== tagId
    );
    setAddFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      tags: updatedAssociatedTags,
    }));
  };

  const options = filteredTagSpecs.map((item) => (
    <Combobox.Option
      value={item.label}
      key={item.label}
      active={
        !!associatedTags.find(
          (tag) =>
            tag.tag_spec_id === item.id &&
            item.subTags.every((subTag) =>
              associatedTags.some(
                (tag) =>
                  tag.tag_spec_id === item.id && tag.value === subTag.value
              )
            )
        )
      }
    >
      <Stack gap="sm">
        <Checkbox
          label={item.label}
          checked={item.subTags.every((subTag) =>
            associatedTags.some(
              (tag) => tag.tag_spec_id === item.id && tag.value === subTag.value
            )
          )}
          onChange={(event) => {
            if (event.currentTarget.checked) {
              // this checks all the subtags automatically if the main tag is checked
              addTagSpec(item.id);
            } else {
              removeTagSpec(item.id);
            }
          }}
        />
        <Box pl="md">
          <Stack gap="xs">
            {item.subTags.map((subTag) => {
              const subTagValue = subTag.value;
              return (
                <Checkbox
                  key={`${item.id}_${subTagValue}`}
                  label={subTagValue}
                  checked={
                    !!associatedTags.find(
                      (tag) =>
                        item.id === tag.tag_spec_id && tag.value === subTagValue
                    )
                  }
                  onChange={({ currentTarget }) => {
                    if (currentTarget.checked) {
                      addTags(item.id, subTagValue);
                    } else {
                      removeTags(item.id, subTagValue);
                    }
                  }}
                />
              );
            })}
          </Stack>
        </Box>
      </Stack>
    </Combobox.Option>
  ));
  return (
    <Combobox
      styles={{
        search: {
          backgroundColor: '#e2e2e2',
        },
      }}
      store={combobox}
      //   onOptionSubmit={handleValueSelect}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          onClick={() => search.length > 2 && combobox.openDropdown()}
        >
          <Pill.Group>
            {associatedTags.map((item) => (
              <Pill
                style={{ border: '1px solid black' }}
                key={item.value}
                withRemoveButton
                onRemove={() => removeTags(item.tag_spec_id, item.value)}
              >
                {item.value}
              </Pill>
            ))}
            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => search.length > 2 && combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder="Search values"
                onChange={(event) => {
                  const newValue = event.currentTarget.value;
                  setSearch(newValue);
                  if (newValue.length > 2) {
                    combobox.openDropdown();
                  } else {
                    combobox.closeDropdown();
                  }
                  combobox.updateSelectedOptionIndex();
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault();
                    const lastChip = associatedTags[associatedTags.length - 1];
                    removeTags(lastChip.tag_spec_id, lastChip.value);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nothing found...</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default NestedDropdown;
