import { generateCRUDOps, API_PATH, request } from './httpService';

const TagService = {
  ...generateCRUDOps(API_PATH.REVIEWS, 'tag-specs'),
  ...generateCRUDOps(API_PATH.REVIEWS, 'tags'),
  bulkDeleteTags: async (tagSpecIds: number[]) => {
    return request({
      url: `${API_PATH.REVIEWS}tags/bulk-delete/`,
      method: 'post',
      data: { tag_ids: tagSpecIds },
    });
  },
  bulkDeleteTagSpecs: async (tagSpecIds: number[]) => {
    return request({
      url: `${API_PATH.REVIEWS}tag-specs/bulk-delete/`,
      method: 'post',
      data: { tagspec_ids: tagSpecIds },
    });
  },
};

export default TagService;
